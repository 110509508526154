
import { defineComponent, ref } from 'vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import TmTextCopy from '@/components/shared/TmTextCopy.vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmEditableText from '@/components/shared/TmEditableText.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'

export default defineComponent({
  components: { TmEllipsis, TmEditableText, Highlight, TmTemplate, TmTextCopy, TmStylizedText },
  setup() {
    const stylizedText = [
      {
        text: 'Lorem ipsum dolor sit ',
        style: 'medium',
      },
      {
        text: 'amet, consectetur adipiscing elit, ',
        style: 'semi-bold',
      },
      {
        text: 'sed do eiusmod tempor incididunt ',
        style: 'bold',
      },
      {
        text: ',ut labore et dolore magna aliqua.',
        color: 'red',
      },
      ' Lorem ipsum dolor sit.',
    ]
    const content = 'Hi {First name}\n\nI just sent your Resume to your email.\nPlease REPLY back to me to CONFIRM you received your Resume OK.'
    const editableText = ref('Some editable text')

    return {
      editableText,
      content,
      stylizedText,
    }
  },
})
